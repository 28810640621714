<template>
  <div class="mcia-outer-conatiner">
    <div class="mcia-inner-container">
      <mds-layout-grid class="product-details-section">
        <mds-row v-if="flipContent">
          <mds-col
            :cols-at-s="11"
            :cols-at-m="11"
            :cols-at-l="6"
            :cols-at-xl="6"
            class="product-details-section-row-flippedimg">
            <img :src="imagePath" />
          </mds-col>
          <mds-col
            :cols-at-s="11"
            :cols-at-m="11"
            :cols-at-l="6"
            :cols-at-xl="5"
            class="product-details-section-row-description"
          >
            <div class="item-intro" v-html="introduction"></div>
            <br />
            <div class="display-content-description" v-html="description"></div>
          </mds-col>
        </mds-row>
        <mds-row v-else>
          <mds-col
            :cols-at-s="11"
            :cols-at-m="11"
            :cols-at-l="6"
            :cols-at-xl="5"
            class="product-details-section-row-description">
            <div class="item-intro" v-html="introduction"></div>
            <br />
            <div class="display-content-description" v-html="description"></div>
          </mds-col>
          <mds-col
            :cols-at-s="11"
            :cols-at-m="11"
            :cols-at-l="6"
            :cols-at-xl="6"
            class="product-details-section-row-img">
            <img :src="imagePath" />
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>

import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";

export default {
  name: "PlatformItem",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  data() {
    return {
      toggle: false,
      showNotification: false,
      flipContent: false,
    };
  },
  computed: {
    imagePath() {
      return require("../../assets/" + this.imageFileName);
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
  },
  methods: {
    getStartedFormToggled() {
      this.toggle = !this.toggle;
    },
    getStartedToggleNotification(toggle) {
      this.showNotification = toggle;
      if (this.showNotification) {
        setTimeout(() => {
          this.showNotification = false;
        }, 1000);
      }
    },
    handleWindowResize() {
      if (window.outerWidth <= 768) {
        this.flipContent = false;
      } else {
        this.flipContent = this.flipped;
      }
    },
  },
  props: {
    introduction: {
      type: String,
      default: "",
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    imageFileName: {
      type: String,
      default: "",
      required: true,
    },
    flipped: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.product-details-section {
  height: 100%;
  margin: 0 auto;
  width: 100%;
  &-row {
    &-description {
      align-self: center;
    }
    &-img {
      display: flex;
      justify-content: right;
      margin-right: 97px;
      width: 430px;
      height: 357px;
      // max-width: -webkit-fill-available;
    }
    &-flippedimg {
      display: flex;
      justify-content: left;
      width: 430px;
      height: 357px;
    }
  }
}
.mcia-outer-conatiner {
  .mcia-inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
    @media (max-width: $mds-breakpoint-m) {
      margin-bottom: 40px;
    }
  }
}

.item-intro{
  @include mds-level-2-heading($bold: true);
  width: 590px;
  @media (max-width: $mds-breakpoint-m) {
    display: contents;
  }
}

.product-details-section-row-img {
  @media (max-width: $mds-breakpoint-m) {
    justify-content: start;
    padding-top: 12px;
    margin-right: 0px;
  }
}
.display-content {
  display: flex;
  &-description {
    @include mds-body-text-l();
    color: $mds-text-color-primary;
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
  }
}
img {
  max-width: -webkit-fill-available;
  @media (max-width: $mds-breakpoint-m) {
    padding-bottom: 12px;
  }
}
</style>
