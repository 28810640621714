<template>
  <div class="clo-page-margin">
    <PlatformItem
      :introduction="platformData[0].introduction"
      :description="platformData[0].description"
      :imageFileName="platformData[0].imageFileName"
      :flipped="platformData[0].flipped"
    />
    <SignUpBanner />
    <PlatformItem
      :introduction="platformData[1].introduction"
      :description="platformData[1].description"
      :imageFileName="platformData[1].imageFileName"
      :flipped="platformData[1].flipped"
    />
  </div>
</template>

<script>
import AnalyticsData from "@/data/cloInsight.json";
import PlatformItem from "@/components/CloInsight/PlatformItem.vue";
import SignUpBanner from "@/components/CloInsight/SignUpBanner.vue";

export default {
  name: "Platform",
  components: {
    PlatformItem,
    SignUpBanner,
  },
  data() {
    return {
      platformData: AnalyticsData?.platform,
    };
  },
};
</script>

<style scoped lang="scss">
.clo-page-margin {
  margin-top: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
}
</style>
