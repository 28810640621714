<template>
  <div class="mcia-outer-container">
    <div class="mcia-inner-container">
      <mds-layout-grid class="hero-banner">
        <mds-row
          class="hero-banner-row"
          align-vertical="center"
          align-horiontal="center"
        >
          <mds-col>
            <div v-html="cloSignupBannerData.title" class="item-title" />
            <div>
              <mds-button
                variation="secondary"
                size="medium"
                target="_blank"
                :href="cloSignupBannerData.signUpLink"
              >
                {{ cloSignupBannerData.signUp }}
              </mds-button>
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { MdsButton } from "@mds/button";
import BannerData from "@/data/banner.json";

export default {
  name: "SignUpBanner",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
  },
  computed: {
    imagePath() {
      return require("../../assets/" + this.cloSignupBannerData.imageFileName);
    },
  },
  data() {
    return {
      cloSignupBannerData: BannerData.cloSignupBanner,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hero-banner {
  height: 100%;
  &-row {
    height: 100%;
    text-align: left;
  }
}

.item-title {
  @include mds-level-2-heading($bold: true);
  width: 590px;
  margin-bottom: 25px;
}

.mcia-outer-container {
  box-sizing: border-box;
  background-image: url("../../assets/analytics-poweredby-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  margin-bottom: 95px;
  .mcia-inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}
</style>
