<template>
  <div>
    <Banner
      :title="cloBannerData.title"
      :description="cloBannerData.description"
      :imageName="cloBannerData.backgroundImage"
      :height="cloBannerData.bannerHeight"
      :margin="cloBannerData.margin"
      :rowHeight="cloBannerData.rowHeight"
    />
    <Platform />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Banner from "../components/Common/Banner.vue";
import Platform from "../components/CloInsight/Platform.vue";
import BannerData from "@/data/banner.json";
import cloData from "@/data/cloInsight.json";

export default {
  name: "Analytics",
  components: {
    Banner,
    Platform,
  },
  created() {
    this.saveSelectedProducts(["clo_insight_model"]);
  },
  mounted() {
    this.saveShowSignInButton(true);
    this.saveActiveSignInLink(this.cloSignInLink);
  },
  destroyed() {
    this.saveShowSignInButton(false);
  },
  data() {
    return {
      cloBannerData: BannerData.cloBanner,
      cloSignInLink: cloData?.signInLink,
    };
  },
  methods: {
    ...mapActions({
      saveActiveSignInLink: "signIn/setActiveSignInLink",
      saveShowSignInButton: "signIn/setSignInVisibility",
      saveSelectedProducts: "signIn/setSelectedProducts",
    }),
  },
};
</script>
